<template>
	<workshop-registration-form is-update :id="id"></workshop-registration-form>
</template>

<script>
	import WorkshopRegistrationForm from '@/views/manage/workshops/registrations/WorkshopRegistrationForm';

	export default {
		name: 'WorkshopRegistrationEdit',
		components: {
			WorkshopRegistrationForm
		},
		data() {
			return {
				id: this.$route.params.regId
			}
		}
	}
</script>